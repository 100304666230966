<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" src="@/assets/images/logo/logo.png" />
    </Header>
    <!-- End Header Area -->

     
    <!-- Start Column Area  -->
    <div class="rn-column-area rn-section-gap bg_color--1 mt--100">
        <v-container>
            <h3 class="mb--100">Τα νέα μου</h3>
            
            <div v-if="history.length == 0  && this.loading == false">
              Δεν υπάρχουν δεδομένα
            </div>
            <div id="historyList" v-else v-for="(history_item, i) in userHistoryPagination()"
                              :key="i">
                    <v-row class="mt--100">
                        <v-col md="6"
                              sm="6"
                              cols="12" >
                            <div class="single-column">
                                <img :src="`${history_item.image}`" width="150px" />
                            </div>
                        </v-col>
                        <v-col md="6"
                              sm="6"
                              cols="12" >
                            <div class="single-column">
                                <h2> {{ history_item.transaction_type_name }} </h2>
                                <p> {{ history_item.transaction_type_name }} πόντοι</p>
                            </div>
                        </v-col>
                    </v-row>    
              </div>
                <b-pagination
                      v-if="getRows() > 0"
                      class="mt--100"
                      v-model="currentPage"
                      :total-rows="getRows()"
                      :per-page="perPage"
                      aria-controls="historyList"
                      align="center"
                    ></b-pagination>  
        </v-container>
    </div>
    <!-- End Column Area  -->
    

    <Footer />
  </div>
</template>

<script>
  import Header from "@/components/header/Header";
  import Footer from "@/components/footer/FooterTwo";
  import axios from 'axios';
  import { mapMutations, mapActions, mapGetters } from "vuex";

  export default {
    name: 'transactions',
    components: {
      Header,
      Footer,
    },
    data() {
      return {        
        currentPage: 1,
        perPage: 5,
        loading: true,
      };
    },
    computed: {
       ...mapGetters (["history"]),
    },
    async  created () {
       if(!this.history){
        this.$loading(true);
        await this.getHistory();
        this.$loading(false);
      }else{
        await this.getHistory();
      }
    },
    methods: {
      ...mapActions(["getHistory"]),
       getRows(){
        return this.history.length;
      },
      userHistoryPagination() {
        return this.history.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
      }
    },
  };
</script>
